import { ProfileLockedService } from "@sportyano/core/services/playerProfile/profileLocked.service";
import { Router } from "@angular/router";
import {
	Component,
	EventEmitter,
	Input,
	Output,
	inject,
	OnDestroy,
	computed,
} from "@angular/core";
import { ShowinterestService } from "@sportyano/core/services/generals/showinterest.service";
import { LanguagesService } from "@sportyano/core/services/language services/languages.service";
import { ShortlistService } from "@sportyano/core/services/shortLists/shortlist.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { FavouriteListService } from "@sportyano/core/services/playerProfile/favourite-list.service";
import { UrlService } from "@sportyano/core/services/url/url.service";
import { ShareModalComponent } from "@sportyano/shared/components/share-modal/share-modal.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { Observable } from "rxjs/internal/Observable";
import { shareReplay } from "rxjs/internal/operators/shareReplay";
import { map } from "rxjs/internal/operators/map";
import { MapUtils } from "@sportyano/shared/utilis/map-utilits";
import { GeneralService } from "@sportyano/core/services/generals/general.service";
import { Sport } from "@sportyano/core/models/sports/sports.model";
import { StateManagementService } from "@sportyano/core/services/state-managment/state-management.service";

@Component({
	selector: "app-cart-profile",
	templateUrl: "./cart-profile.component.html",
	styleUrl: "./cart-profile.component.scss",
	standalone: true,
	imports: [ShareModalComponent, CommonModule, TranslateModule],
})
export class CartProfileComponent implements OnDestroy {
	// INPUTS
	@Input() playerData: any;
	@Input() showUnlock: boolean = true;
	@Input() showShortListButton: boolean = false;
	@Input() hideFavInAccount: boolean = false;

	// Outputs
	@Output() showPlayer = new EventEmitter<string>();

	// Injects
	private _shortListService = inject(ShortlistService);
	private _showInterest = inject(ShowinterestService);
	private _unlock = inject(ProfileLockedService);
	private _toastrMessages = inject(ToasterMessagesService);
	private _favouriteService = inject(FavouriteListService);
	private _stateManagmentService = inject(StateManagementService);
	private _generalService = inject(GeneralService);

	public sportsList$: Observable<Map<number, Sport>> = this._generalService
		.getSports()
		.pipe(
			shareReplay(1),
			map((m) => MapUtils.arrayToMap(m.records, "id"))
		);
	// Public
	currentLanguage: string = "ar";

	userType: UserType;
	permission = permission;
	userAuthenticationState: boolean = false;
	currentUrl: string;
	public currentWebsiteUrl: string;

	// Flags
	showShare: boolean = false;
	isComparsion: boolean = false;
	showDetails: boolean = false;
	playerState = computed(() =>
		this._stateManagmentService
			.stateStore()
			.players.find((f) => f.id === this.playerData.id)
	);
	constructor(
		private languagesService: LanguagesService,
		private _authService: AuthService,
		private router: Router,
		private urlService: UrlService
	) {
		this.currentWebsiteUrl = this.urlService.getWebsiteUrl();
	}
	ngOnInit(): void {
		this.languagesService.language$.subscribe({
			next: (language: string) => {
				this.currentLanguage = language;
			},
		});
		this.userType = this._authService.getUserType();
		this.userAuthenticationState =
			this._authService.getAuthenticationStateSync();
		this.currentUrl = this.router.url;
	}

	addToShortList() {
		if (this.userAuthenticationState) {
			this._favouriteService
				.toggleFavourite(this.playerData?.id, "player")
				.subscribe({
					next: (res: any) => {
						this.playerData.is_favorite = !this.playerData.is_favorite;
						this._toastrMessages.showSuccess(res.message);
					},
				});
		} else {
			this._authService.setLoginPopupState(true);
		}
	}

	addStarToPlayer() {
		this._showInterest.makePlayersInterest(this.playerData.id).subscribe({
			next: (res: any) => {
				this.playerData.is_interest_shown = true;
				this._toastrMessages.showSuccess(res.message);
			},
		});
		this._showInterest.makePlayersInterest(this.playerData.id).subscribe({
			next: (res: any) => {
				this.playerData.is_interest_shown = true;
				this._toastrMessages.showSuccess(res.message);
			},
		});
	}

	public likePlayer(likeState: boolean = true): void {


		this._showInterest.likePlayer(this.playerData.player_id).subscribe({
			next: (res: any) => {
				this.playerData.is_interest_shown = true;
				this._stateManagmentService.setPlayerState({
					id: this.playerData.player_id,
					is_interest_shown: true,
				});
				++this.playerData.likes;
			},
		});
	}

	unlockProfile() {
		if (this.userAuthenticationState) {
			this._unlock
				.makePlayersUnlock(this.playerData.id)
				.subscribe((data: any) => {
					this.playerData.is_unlocked = true;
					this._toastrMessages.showSuccess(data.message);
				});
		} else this._authService.setLoginPopupState(true);
	}

	addToFavourite() {
		if (this.userAuthenticationState) {
			console.log('favs');
			
			this._favouriteService
				.toggleFavourite(this.playerData.id, "player")
				.subscribe({
					next: (res) => {
						this.playerData.is_favorite=!this.playerData.is_favorite
						this._toastrMessages.showSuccess(res.message);
					},
				});
		}
	}

	openPlayer() {
		this.showPlayer.emit(String(this.playerData?.id));
		this.router.navigateByUrl(
			"/main/players/" + (this.playerData?.slug || 1)
		);
	}

	ngOnDestroy(): void {
		this._authService.setLoginPopupState(false);
	}
}
