<div
	class="academy p-2 bg-inputFieldBackground rounded-lg overflow-hidden relative flex gap-3 flex-wrap lg:flex-nowrap cursor-pointer">
	<div class="photo overflow-hidden rounded-lg w-full lg:w-1/3 h-56">
		<img class="w-full h-full object-cover" [src]="data?.cover_image ?? '../../../../assets/images/image/academy-cover.jpg'" alt="" />
	</div>
	<div class="content w-full flex flex-col justify-between items-start">
		<h3 class="title text-white capitalize text-2xl">
			{{data?.academy_name}}
		</h3>
		<p class="brach text-white capitalize">
			{{data?.branch_name}}
		</p>
		<!-- <div class="star mt-2 flex gap-1 items-center">
			<img class="size-4" src="assets/icons/Icon ionic-ios-star.svg" alt="" />
			<div class="num-rate text-white">240</div>
		</div> -->
		<p class="desc mt-3 text-fontColor text-sm word-break-word">
			{{data?.description |slice:0:150}}
		</p>
		<ul class="exercises mt-5 flex justify-start gap-4 list-none text-white">
			@for(item of data?.sports; track $index){
			<li class="text-center">
				<p class="title mt-2 text-xs"> {{ item?.name }} </p>
			</li>
			}
		</ul>
	</div>
</div>
