import { CommonModule, DatePipe, formatDate } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	inject,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	PLATFORM_ID,
	signal,
	SimpleChanges,
	WritableSignal,
} from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import {
	permission,
	UserType,
} from "@sportyano/core/models/permissions/permission";
import {
	ITimeSlotRequest,
	TimeSlot,
	TimeSlotResponse,
} from "@sportyano/core/models/programs/time-slots.model";
import { ProgramsScheduleService } from "@sportyano/core/services/academy/programs-schedule/programs.schedule.service";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { IPlaygroundReservedResponse } from "@sportyano/shared/components/custom-calendar/models/const/reserved-response";
import { tap } from "rxjs";
import { CheckSlotAvailability } from "@sportyano/shared/components/calendar-scheduler/pipe/check-slot-availability.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { MenuModule } from "primeng/menu";
import { ButtonModule } from "primeng/button";
import { DateTimeUtils } from "@sportyano/shared/utilis/date-time-utilits";
import { PayloadUtils } from "@sportyano/shared/utilis/payload-utilits";
import { PlaygroundService } from "@sportyano/core/services/playgrounds/playground.service";
import { TimeFormatPipe } from "@sportyano/shared/pipes/time-format/time-format.pipe";
import { CheckSlotSelected } from "@sportyano/shared/components/calendar-scheduler/pipe/check-slot_selected.pipe";
import { BookingService } from "@sportyano/core/services/booking/booking.service";

@Component({
	selector: "calendar-scheduler",
	templateUrl: "./calender-scheduler.component.html",
	styleUrl: "./calender-scheduler.component.scss",
	standalone: true,
	providers: [DatePipe],
	imports: [
		CommonModule,
		TranslateModule,
		CheckSlotAvailability,
		MatMenuModule,
		MenuModule,
		ButtonModule,
		TimeFormatPipe,
		CheckSlotSelected,
	],
})
export class CalendarSchedulerComponent implements OnInit, OnChanges {
	// INPUTS
	@Input() role: "admin" | "user";
	@Input() startDate: string | Date;
	@Input() timeFromTo: { from: string; to: string };
	@Input() typeOfCalender: permission.playground | permission.academy =
		permission.academy;
	@Input() navigationControllerState: boolean = true;
	userType: UserType;
	@Input() playgroundBooking: string[];
	@Input() daysOff: number[] = [];
	@Input() academyBooking: string[] = [];
	@Input() programDuration: number;
	@Input() discount: number;
	@Input() price: number;

	// OUTPUTS
	@Output() currentChosenSlots: EventEmitter<Array<ITimeSlotRequest>> =
		new EventEmitter<Array<ITimeSlotRequest>>();
	// Inject
	private _bookingService = inject(BookingService);
	// PRIVATE
	private _reservedPlayground: Map<Date, number> = new Map<Date, number>();
	// PUBLIC
	programId: string;
	public permission = permission;
	public bookedSlots: WritableSignal<Map<string, Array<TimeSlot>>> = signal(
		new Map()
	);
	private endDate: Date = new Date("2026-7-22 15:32:10"); // Your end date

	public localBookedSlots: WritableSignal<Map<string, ITimeSlotRequest>> =
		signal(new Map());
	currentReservedPlaygroundSlots: Date[] = [];
	chosenSuitableDates: any[] = [];
	public availableDaysOfWeek: WritableSignal<string[]> = signal([]);
	bookingDays: string[] = [
		"Saturday",
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
	];
	dateNow: Date = new Date();
	todayDate: Date = new Date();
	week: Date = new Date();
	selectedHours: CalendarItem[] = [];
	// FLAGS
	calenderState: boolean;
	showOptions: boolean[][] = [];
	isDateExceeds: WritableSignal<boolean> = signal(false);
	isPreviousWeekExceeds: WritableSignal<boolean> = signal(true);

	constructor(
		@Inject(PLATFORM_ID) private platform: object,
		private translate: TranslateService,
		private authService: AuthService,
		private datePipe: DatePipe,
		private programSchedule: ProgramsScheduleService,
		private _playgroundService: PlaygroundService,
		private activatedRoute: ActivatedRoute,
		private _cd: ChangeDetectorRef
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes["daysOff"]?.currentValue.length > 0) {
			let daysOff = changes["daysOff"].currentValue;
			this.availableDaysOfWeek.set(
				this.availableDaysOfWeek().filter(
					(day) => !daysOff.includes(day)
				)
			);
			this._generateDateArray(this.startDate, this.endDate);
		}

		if (changes["timeFromTo"]?.currentValue) {
			this.playgroundBooking = DateTimeUtils.generateTimeArrayFromString(
				changes["timeFromTo"].currentValue
			);
		}

		if (changes["startDate"]?.currentValue) {
			this._setStartedDate(
				changes["startDate"]?.currentValue,
				this.dateNow
			);
		}
	}

	ngOnInit(): void {
		this._getProgramIdFromParam();
		this.userType = this.authService.getUserType();
		this.getAllTimeSlots();
		this.week = new Date(this.dateNow);
		this.week.setDate(this.dateNow.getDate() + 7);
		if (this.role === "admin") {
			let length =
				this.userType === permission.academy
					? 3
					: this.playgroundBooking?.length;

			for (let i = 0; i < length; i++) {
				this.showOptions[i] = [];
				for (let j = 0; j < 7; j++) {
					this.showOptions[i][j] = false;
				}
			}
		}

		this._generateEndDate(this.programDuration);
		this._generateDateArray(this.startDate, this.endDate);
	}
	private _generateEndDate(programDuration: number | null = null): void {
		this.endDate = programDuration
			? DateTimeUtils.calculateEndDateByWeeks(
					this.startDate as string,
					programDuration
			  )
			: DateTimeUtils.dateAfterMonths(3);
	}

	private _getProgramIdFromParam(): void {
		this.programId =
			this.activatedRoute.snapshot.params["programId"] ||
			this.activatedRoute.snapshot.params["playgroundId"] ||
			this.activatedRoute.snapshot.params["id"];
	}

	private _generateDateArray(startDateStr: string | Date, endDate: Date) {
		const today = new Date();
		const startDate = new Date(startDateStr);

		// Start with today if the start date is before today
		const start = startDate < today ? today : startDate;
		// Calculate the end date for the array
		const potentialEnd = new Date(start);
		potentialEnd.setDate(start.getDate() + 6);

		const end = potentialEnd > endDate ? endDate : potentialEnd;

		const dateArray: string[] = [];
		let currentDate = new Date(start);

		// CLEAR
		this.availableDaysOfWeek.set([]);
		// Generate the date array
		while (currentDate <= end) {
			const dayOfWeek = currentDate.getDay();
			if (!this.daysOff.includes(dayOfWeek)) {
				this.availableDaysOfWeek().push(this._formatDate(currentDate));
			}
			currentDate.setDate(currentDate.getDate() + 1);
		}
	}

	private _formatDate(date: Date): string {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	}

	public onDisableAction(
		slotData: { date: string; time: string },
		slotState: TimeSlot | "false"
	) {
		const payloadKey = slotData.date.concat(
			" ",
			DateTimeUtils.convertTo24Hour(slotData.time)
		);
		this._generatePayload(payloadKey, slotState);
	}

	private _generatePayload(
		payloadKey: string,
		slotState: TimeSlot | "false"
	) {
		if (this.role === "admin") {
			this._generateAdminPayload(payloadKey, slotState);
		} else {
			this._generateUserPayload(payloadKey, slotState);
		}

		this.localBookedSlots.set(new Map(this.localBookedSlots()));
		this.currentChosenSlots.emit(
			Array.from(this.localBookedSlots().values())
		);
	}

	private _generateAdminPayload(
		payloadKey: string,
		slotState: TimeSlot | "false"
	) {
		if (this.localBookedSlots().has(payloadKey)) {
			// Check if Contain Slot ID
			if (
				this.typeOfCalender == permission.academy &&
				this.localBookedSlots().get(payloadKey)?.slotId
			) {
				// CHANGE STATUS
				const slot = this.localBookedSlots().get(payloadKey);

				this.localBookedSlots().set(payloadKey, {
					...slot!,
					status: !slot?.status,
				});
			} else {
				// REMOVE FORM PAYLOAD
				this.localBookedSlots().delete(payloadKey);
			}
		} else {
			// Check if slot status inActive or New
			if (slotState !== "false") {
				this.localBookedSlots().set(payloadKey, {
					slotId: slotState?.id,
					slot: payloadKey,
					status: !PayloadUtils.toBoolean(slotState.status),
				});
			} else {
				// Create New Slot
				this.localBookedSlots().set(payloadKey, {
					slot: payloadKey,
					status: this.typeOfCalender == permission.academy ?? false,
				});
			}
		}
		console.log("ADMIN", this.localBookedSlots());
	}

	private _generateUserPayload(
		payloadKey: string,
		slotState: TimeSlot | "false"
	) {
		if (this.localBookedSlots().has(payloadKey)) {
			this.localBookedSlots().delete(payloadKey);
		} else {
			// Check if slot status inActive or New
			if (slotState !== "false") {
				this.localBookedSlots().set(payloadKey, {
					slotId: slotState?.id,
					slot: payloadKey,
					status: !PayloadUtils.toBoolean(slotState.status),
					price: this._bookingService.checkForApplyDiscount(
						payloadKey,
						this.discount,
						this.price
					),
				});
			} else {
				// Create New Slot
				this.localBookedSlots().set(payloadKey, {
					slot: payloadKey,
					status: this.typeOfCalender == permission.academy ?? false,
					price: this._bookingService.checkForApplyDiscount(
						payloadKey,
						this.discount,
						this.price
					),
				});
			}
		}
	}
	//-----------------------------------------------------------------------------
	getAllTimeSlots(startDate?: Date, endDate?: Date) {
		if (this.typeOfCalender === permission.academy) {
			this._getProgramSchedule();
		} else {
			this._getPlaygroundSchedule(startDate, endDate);
		}
	}

	private _getProgramSchedule(): void {
		this.programSchedule
			.getTimeSlots(this.programId, permission.academy)
			.subscribe({
				next: (res) => {
					res.slots.forEach((slot) => {
						const [slotDate, slotTime] = slot.slot.split(" ");
						// check if the key is in Map
						if (!this.bookedSlots().has(slotDate)) {
							this.bookedSlots().set(slotDate, [slot]);
						} else {
							this.bookedSlots().get(slotDate)?.push(slot);
						}
					});
					this.calenderState = true;
				},
			});
	}

	private _getPlaygroundSchedule(startDate?: Date, endDate?: Date): void {
		this._playgroundService
			.getReservedPlayground(this.programId)
			.pipe(tap((t) => {}))
			.subscribe({
				next: (res: TimeSlotResponse) => {
					res.slots.forEach((slot) => {
						const [slotDate, slotTime] = slot.slot.split(" ");
						// check if the key is in Map
						if (!this.bookedSlots().has(slotDate)) {
							this.bookedSlots().set(slotDate, [slot]);
						} else {
							this.bookedSlots().get(slotDate)?.push(slot);
						}
					});
					this.calenderState = true;
				},
			});
	}

	navigateToNextWeek() {
		const nextWeekStartDate = new Date(this.dateNow);
		nextWeekStartDate.setDate(this.dateNow.getDate() + 7);

		const nextWeekEndDate = new Date(nextWeekStartDate);
		nextWeekEndDate.setDate(nextWeekStartDate.getDate() + 6);

		if (nextWeekEndDate > this.endDate) {
			nextWeekEndDate.setTime(this.endDate.getTime());
		}

		this.isPreviousWeekExceeds.set(false);
		// Open Back Flag
		if (nextWeekStartDate >= this.endDate) {
			this.isDateExceeds.set(true);
			return;
		}
		// Stop if the next week start date exceeds the end date
		this.dateNow = nextWeekStartDate;
		this.week = nextWeekEndDate;
		this._generateDateArray(nextWeekStartDate, this.endDate);
	}

	navigateToPreviousWeek() {
		this.dateNow = new Date(
			this.dateNow.setDate(this.dateNow.getDate() - 7)
		);
		// Stop if the next week start date exceeds the end date
		console.log(
			"CHECK STOP : dateNow",
			formatDate(this.dateNow, "yyyy-MM-dd", "en-US")
		);
		console.log("CHECK STOP : startDate", this.startDate);

		this.week = new Date(this.week.setDate(this.week.getDate() - 7));
		this.isDateExceeds.set(false);

		this._generateDateArray(this.dateNow, this.endDate);
		if (formatDate(this.dateNow, "yyyy-MM-dd", "en-US") <= this.startDate) {
			this.isPreviousWeekExceeds.set(true);
			return;
		}

		this.getAllTimeSlots(
			this.dateNow,
			new Date(this.dateNow.getTime() + 7 * 24 * 60 * 60 * 1000)
		);
	}

	getDay(weekDay: number): Date {
		const date = new Date(this.dateNow);
		var distance = (weekDay + 7 - date.getDay()) % 7;
		date.setDate(date.getDate() + distance);
		return date;
	}

	private _setStartedDate(startDateStr: string, today: Date): void {
		const startDate = new Date(startDateStr);
		// Start with today if the start date is before today
		this.dateNow = startDate < today ? today : startDate;
	}
}

interface CalendarItem {
	day: number;
	hour: string;
}

interface SlotGroup {
	[key: string]: TimeSlot[];
}

const slots: TimeSlot[] = [];
