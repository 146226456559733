<section class="w-full bg-darkBlue pt-32">
	<div class="lg:w-4/5 mx-auto w-full px-10" *ngIf="step < 3">
		<div class="flex justify-between items-center flex-wrap mb-10">
			<p class="text-3xl text-fontColor font-bold md:mb-0 mb-4">
				{{ programDetails?.name_ar }}
			</p>
			<div class="w-[250px] flex justify-between items-center">
				<div
					class="w-[38px] h-[38px] bg-darkGrey rounded-full text-fontColor flex justify-center items-center font-bold"
					[class.bg-textGreen]="step == 1 || step == 2"
				>
					1
				</div>
				<div
					class="w-[170px] h-[1px] border border-dashed border-lightGray"
				></div>
				<div
					class="w-[38px] h-[38px] bg-darkGrey rounded-full text-fontColor flex justify-center items-center font-bold"
					[class.bg-textGreen]="step == 2"
				>
					2
				</div>
			</div>
		</div>

		<ng-container *ngIf="step == 1">
			<h3 class="text-textYellow font-semiBold text-lg mb-4">
				{{ "reservation_name" | translate }}
			</h3>
			<div
				class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mb-4"
			>
				@if (userType === permission.viwer && viewerProfiles.length > 0)
				{
				<p-dropdown
					[(ngModel)]="viewerSelectedId"
					[ngModelOptions]="{ standalone: true }"
					name="viewerProfiles"
					[options]="viewerProfiles"
					optionLabel="name"
					optionValue="id"
				>
				</p-dropdown>
				} @else if (userType === permission.viwer &&
				viewerProfiles.length <= 0) {
				<div
					class="text-start mb-4 cursor-pointer flex gap-2"
					routerLink="/main/account/account-management/profiles"
				>
					<p
						class="my-2 text-white bg-semiDarkBlue w-80 max-w-full p-3 rounded-lg flex gap-1 items-center"
					>
						<svg
							_ngcontent-ng-c576268256=""
							style="width: 15px"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							stroke="currentColor"
							viewBox="0 0 45.333 45.333"
							class="w-1/3 transition-all group-hover:scale-125"
						>
							<g
								_ngcontent-ng-c576268256=""
								id="Group_41201"
								data-name="Group 41201"
								transform="translate(3.5 3.5)"
							>
								<path
									_ngcontent-ng-c576268256=""
									id="Path_33303"
									data-name="Path 33303"
									d="M18,4.5V42.833"
									transform="translate(1.167 -4.5)"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="7"
								></path>
								<path
									_ngcontent-ng-c576268256=""
									id="Path_33309"
									data-name="Path 33309"
									d="M0,0V38.333"
									transform="translate(38.333 19.166) rotate(90)"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="7"
								></path>
							</g>
						</svg>
						{{ "profile_booking_validation" | translate }}
					</p>
				</div>
				} @if (userType === permission.player && playerInfoData) {
				<div class="text-start mb-4">
					<p
						class="my-2 text-white bg-semiDarkBlue w-80 max-w-full p-3 rounded-lg"
					>
						{{ playerInfoData.full_name }}
					</p>
				</div>
				} @else if (userType === permission.player && !playerInfoData){
				<div
					class="text-start mb-4 cursor-pointer flex gap-2"
					routerLink="/main/account/profile-management/player-info"
				>
					<p
						class="my-2 text-white bg-semiDarkBlue w-80 max-w-full p-3 rounded-lg flex gap-1 items-center"
					>
						<svg
							_ngcontent-ng-c576268256=""
							style="width: 15px"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							stroke="currentColor"
							viewBox="0 0 45.333 45.333"
							class="w-1/3 transition-all group-hover:scale-125"
						>
							<g
								_ngcontent-ng-c576268256=""
								id="Group_41201"
								data-name="Group 41201"
								transform="translate(3.5 3.5)"
							>
								<path
									_ngcontent-ng-c576268256=""
									id="Path_33303"
									data-name="Path 33303"
									d="M18,4.5V42.833"
									transform="translate(1.167 -4.5)"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="7"
								></path>
								<path
									_ngcontent-ng-c576268256=""
									id="Path_33309"
									data-name="Path 33309"
									d="M0,0V38.333"
									transform="translate(38.333 19.166) rotate(90)"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="7"
								></path>
							</g>
						</svg>
						{{ "profile_booking_validation" | translate }}
					</p>
				</div>
				} @if (userType === permission.academy || userType ===
				permission.playground) {
				<div class="text-start mb-4">
					<p
						class="my-2 text-white bg-semiDarkBlue w-80 max-w-full p-3 rounded-lg"
					>
						{{ userDate.name_en }}
					</p>
				</div>
				}
			</div>

			<div
				*ngIf="programDetails"
				class="schedule-details flex flex-wrap gap-4"
			>
				<!-- Sport -->
				<ng-container
					*ngTemplateOutlet="
						itemData;
						context: {
							label: 'account.academy_programs_schedule.playground_type',
							value:
								translateService.currentLang == 'en'
									? programDetails?.sport.name_en
									: programDetails?.sport.name_ar
						}
					"
				>
				</ng-container>

				<!-- Address -->
				<ng-container
					*ngTemplateOutlet="
						itemData;
						context: {
							label: 'account.account_management.account_info.address',
							value:
								translateService.currentLang == 'en'
									? programDetails?.branch.address_en
									: programDetails?.branch.address_ar
						}
					"
				>
				</ng-container>

				<!-- Description -->
				<ng-container
					*ngTemplateOutlet="
						itemData;
						context: {
							label:
								typeOfProgram == BOOKING_SCHEDULE.academy
									? 'account.branches_management.add_branch.program_description'
									: 'account.branches_management.add_branch.playground_description',
							value:
								translateService.currentLang == 'en'
									? programDetails.description_en
									: programDetails.description_ar
						}
					"
				>
				</ng-container>

				<!-- Session duration -->
				<ng-container *ngIf="typeOfProgram == BOOKING_SCHEDULE.academy">
					<ng-container
						*ngTemplateOutlet="
							itemData;
							context: {
								label: 'session_duration',
								value: programDetails?.duration_session,
								valueLabel: 'hour'
							}
						"
					>
					</ng-container>
				</ng-container>

				<!-- Price -->
				<ng-container
					*ngTemplateOutlet="
						itemData;
						context: {
							label: 'account.account_management.My_Booking.Price',
							value: programDetails?.price,
							valueLabel: 'le'
						}
					"
				>
				</ng-container>

				<!--  Morning Price -->
				<ng-container
					*ngIf="typeOfProgram == BOOKING_SCHEDULE.playground"
				>
					<ng-container
						*ngTemplateOutlet="
							itemData;
							context: {
								label: 'booking.morningPrice',
								subLabel: 'booking.morningTime',
								value:
									(1 - programDetails?.discount / 100) *
									programDetails?.price,
								valueLabel: 'le'
							}
						"
					>
					</ng-container>
				</ng-container>

				<!-- Total Price -->
				<div
					*ngIf="
						typeOfProgram == BOOKING_SCHEDULE.playground &&
						selectedSlots()?.length
					"
					class="flex flex-col items-start gap-4 bg-gray-900 p-4 rounded-2xl"
				>
					<div class="flex flex-col w-full justify-between gap-2">
						<small class="text-textGreen">
							{{ "payment_info.total_price" | translate }}
						</small>
						<small class="text-semiDarkGrey">
							{{
								("booking.numberOfBookingSlots" | translate) +
									" " +
									selectedSlots()?.length
							}}
						</small>
						<p class="text-fontColor text-base">
							{{ bookingPrice }}
							<small>{{ "le" | translate }}</small>
						</p>
					</div>
				</div>
			</div>

			<div class="flex justify-start mb-4 mt-8">
				<p class="text-textYellow font-semiBold text-lg">
					{{ "SPORT_ACADEMIES.Choose" | translate }}
					{{ programDetails.number_of_sessions }}
					{{ "SPORT_ACADEMIES.Choose_Suitable_Days" | translate }}
				</p>
			</div>

			<div class="w-full mb-16">
				@if (typeOfProgram === permission.academy) {
				<calendar-scheduler
					[typeOfCalender]="permission.academy"
					[navigationControllerState]="false"
					role="user"
					[startDate]="todayDate"
					[daysOff]="daysOff"
					[academyBooking]="programDetails?.program_start_times"
					(currentChosenSlots)="selectedSlots.set($event)"
					[price]="programDetails?.price"
					[discount]="programDetails?.discount"
				></calendar-scheduler>

				}@else {
				<calendar-scheduler
					[typeOfCalender]="permission.playground"
					[navigationControllerState]="true"
					role="user"
					[startDate]="todayDate"
					[daysOff]="daysOff"
					[timeFromTo]="{
						from: programDetails?.time_from,
						to: programDetails?.time_to
					}"
					(currentChosenSlots)="selectedSlots.set($event)"
					[price]="programDetails?.price"
					[discount]="programDetails?.discount"
				></calendar-scheduler>

				}
			</div>
			<div class="flex justify-end mb-8">
				<button
					(click)="submitSchedule()"
					class="ms-auto pe-3 flex flex-row justify-center items-center w-40 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-lg shadow-md hover:border-lightBlue hover:bg-lightBlue hover:text-fontColor"
				>
					{{ "SPORT_ACADEMIES.Next" | translate }}
				</button>
			</div>
		</ng-container>

		<ng-container *ngIf="step == 2">
			<div class="grid grid-cols-4 gap-8 mb-10">
				<div
					class="rounded-md w-full p-8 lg:col-span-3 col-span-4 flex flex-wrap gap-4 items-baseline"
				>
					<!-- program_name -->
					<ng-container
						*ngIf="typeOfProgram == BOOKING_SCHEDULE.academy"
					>
						<ng-container
							*ngTemplateOutlet="
								itemData;
								context: {
									label: 'account.academy_programs_schedule.table.program_name',
									value: programDetails.name,
								
								}
							"
						>
						</ng-container>
					</ng-container>
					<!-- total_price -->
					<ng-container
						*ngTemplateOutlet="
							itemData;
							context: {
								label: 'payment_info.total_price',
								value:
									typeOfProgram == BOOKING_SCHEDULE.playground
										? bookingPrice
										: programDetails.price,
								valueLabel: 'le'
							}
						"
					>
					</ng-container>
					@if (typeOfProgram === permission.academy) {
					<!-- StartingDate -->
					<ng-container
						*ngTemplateOutlet="
							itemData;
							context: {
								label: 'SPORT_ACADEMIES.Starting_Date',
								value: selectedStartingDate | date : 'longDate'
							}
						"
					>
					</ng-container>

					<!-- weeks -->
					<ng-container
						*ngTemplateOutlet="
							itemData;
							context: {
								label: 'account.academy_programs_schedule.program_duration',
								value: programDetails.duration,
								valueLabel:
									'account.academy_programs_schedule.weeks'
							}
						"
					>
					</ng-container>

					}
					<!-- Number Of slots -->
					<ng-container
						*ngIf="typeOfProgram == BOOKING_SCHEDULE.playground"
					>
						<ng-container
							*ngTemplateOutlet="
								itemData;
								context: {
									label: 'booking.numberOfBookingSlots',
									value: selectedSlots()?.length
								}
							"
						>
						</ng-container>
					</ng-container>
					<!-- Booking slots -->
					<div
						class="flex flex-col items-start gap-4 bg-semiDarkBlue p-4 rounded-2xl"
					>
						<div class="flex flex-col w-full justify-between gap-2">
							<small class="text-textGreen">
								{{ "booking.BookingSlots" | translate }}
							</small>
							<ul class="text-white">
								@for (slot of selectedSlots(); track $index) {
								<li>
									<small>{{ slot?.slot }}</small>
								</li>
								}
							</ul>
						</div>
					</div>
				</div>
				<div
					*ngIf="
						userType === permission.viwer &&
						storedSubmittedViewerProfile
					"
					class="bg-semiLightGray rounded-md w-full min-h-[190px] py-8 px-3 lg:col-span-1 col-span-4"
				>
					<div
						class="h-full gap-5 flex flex-col justify-around items-start"
					>
						<div class="flex flex-col justify-start items-start">
							<p class="text-fontColor text-xl">
								{{ "reservation_name" | translate }}
							</p>
							<p class="text-textYellow text-base">
								{{ storedSubmittedViewerProfile | translate }}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="flex justify-start mb-4">
				<p class="text-textYellow font-semiBold text-lg">
					{{ "SPORT_ACADEMIES.Choose_Payment_Method" | translate }}
				</p>
			</div>

			<form
				[formGroup]="paymentMethodForm"
				class="flex justify-start items-center mb-8"
			>
				<button
					(click)="setPaymentMethod(PAYMENT_METHOD.cash)"
					class="bg-semiLightGray px-2 w-[180px] h-[50px] rounded-md flex justify-start items-center border-2 cursor-pointer"
					[ngClass]="
						paymentMethodForm.get('payment_method')?.value ==
						PAYMENT_METHOD.cash
							? 'border-lightBlue'
							: 'border-semiLightGray'
					"
				>
					<div class="text-base font-semibold text-center">
						<p-radioButton
							name="payment_method"
							[value]="PAYMENT_METHOD.cash"
							formControlName="payment_method"
						></p-radioButton>
					</div>
					<p class="ms-6 font-bold text-fontColor text-xl">
						{{ "booking.paymentMethod.cash" | translate }}
					</p>
				</button>

				<button
					(click)="setPaymentMethod(PAYMENT_METHOD.visa)"
					class="ms-4 bg-semiLightGray px-2 w-[180px] h-[50px] rounded-md flex justify-start items-center border-2 cursor-pointer"
					[ngClass]="
						paymentMethodForm.get('payment_method')?.value ==
						PAYMENT_METHOD.visa
							? 'border-lightBlue'
							: 'border-semiLightGray'
					"
				>
					<div class="text-base font-semibold text-center">
						<p-radioButton
							name="payment_method"
							[value]="PAYMENT_METHOD.visa"
							formControlName="payment_method"
						></p-radioButton>
					</div>
					<img
						src="assets/images/image/Visa_Inc._logo.svg.png"
						class="ms-6"
						alt=""
					/>
				</button>
			</form>

			<div class="flex justify-end items-center mb-8">
				<button
					(click)="step = 1"
					class="pe-3 mx-3 flex flex-row justify-center items-center w-40 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-lg shadow-md hover:border-lightBlue hover:bg-lightBlue hover:text-fontColor"
				>
					{{ "SPORT_ACADEMIES.Back" | translate }}
				</button>

				<button
					[disabled]="paymentMethodForm.invalid"
					(click)="onBooking()"
					class="pe-3 flex flex-row justify-center items-center w-40 h-10 bg- border text-base rounded-lg shadow-md"
					[ngClass]="
						paymentMethodForm.valid
							? 'border-lightBlue bg-lightBlue text-fontColor'
							: 'bg-darkGrey text-gray-500'
					"
				>
					{{ "SPORT_ACADEMIES.Next" | translate }}
				</button>
			</div>
		</ng-container>
	</div>

	<ng-container *ngIf="step == 3">
		<div class="lg:w-4/5 mx-auto w-full px-4">
			<div
				class="w-full bg-semiLightGray rounded-md min-h-[290px] p-8 mb-10"
			>
				<p class="text-textGreen text-3xl font-bold text-center mb-6">
					{{ "SPORT_ACADEMIES.RESERVATION_CONFIRMED" | translate }}
				</p>
				@if (typeOfProgram === permission.academy) {
				<p class="text-fontColor text-xl text-center mb-6">
					{{ "SPORT_ACADEMIES.Reservation_Details" | translate }}
				</p>

				}

				<div class="flex justify-center items-center mx-auto mb-6">
					@if(typeOfProgram === permission.academy){
					<div class="flex flex-col">
						<div class="flex justify-start items-center">
							<p
								class="text-fontColor font-bold text-base text-center"
							>
								{{ "chosen_program_start_date" | translate }}
							</p>
							<p class="mx-2 text-base text-textGreen">
								..............
							</p>
							<p class="text-textGreen text-base text-center">
								{{ selectedStartingDate | date : "longDate" }}
							</p>
						</div>
						<div class="flex justify-start items-center">
							<p
								class="text-fontColor font-bold text-base text-center"
							>
								{{
									"account.academy_programs_schedule.program_duration"
										| translate
								}}
							</p>
							<p class="mx-2 text-base text-textGreen">
								..............
							</p>
							<p class="text-textGreen text-base text-center">
								{{ programDetails.duration }}
							</p>
						</div>
						<div class="flex justify-start items-center">
							<p
								class="text-fontColor font-bold text-base text-center"
							>
								{{
									"account.academy_programs_schedule.table.program_name"
										| translate
								}}
							</p>
							<p class="mx-2 text-base text-textGreen">
								..............
							</p>
							<p class="text-textGreen text-base text-center">
								{{ programDetails.name_ar }}
							</p>
						</div>
					</div>
					} @else {
					<div class="flex flex-col gap-y-3">
						<div class="flex justify-start items-start">
							<p
								class="text-fontColor font-bold text-base text-center"
							>
								{{ "chosen_program_start_date" | translate }}
							</p>
							<p class="mx-2 text-base text-textGreen">
								..............
							</p>
							<div>
								@for (date of storedSubmittedDatesForReview;
								track $index) {
								<p class="text-textGreen text-base text-center">
									{{ date | date : "medium" }}
								</p>
								}
							</div>
						</div>
						<div class="flex justify-start items-center">
							<p
								class="text-fontColor font-bold text-base text-center"
							>
								{{
									"account.academy_programs_schedule.table.Playground_name"
										| translate
								}}
							</p>
							<p class="mx-2 text-base text-textGreen">
								..............
							</p>
							<p class="text-textGreen text-base text-center">
								{{ programDetails.name_ar }}
							</p>
						</div>
					</div>
					}
				</div>

				<div class="flex justify-center items-center">
					<a
						routerLink="/main"
						class="mx-3 flex flex-row justify-center items-center w-40 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-lg shadow-md hover:border-lightBlue hover:bg-lightBlue hover:text-fontColor"
					>
						{{ "SPORT_ACADEMIES.Home" | translate }}
					</a>
					<a
						routerLink="/main/account/my-booking/upComing"
						class="mx-3 flex flex-row justify-center items-center w-40 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-lg shadow-md hover:border-lightBlue hover:bg-lightBlue hover:text-fontColor"
					>
						{{ "SPORT_ACADEMIES.my_booking" | translate }}
					</a>
				</div>
			</div>
		</div>
	</ng-container>
</section>

<ng-template
	#itemData
	let-label="label"
	let-subLabel="subLabel"
	let-value="value"
	let-valueLabel="valueLabel"
>
	<div
		class="flex flex-col items-start gap-4 bg-semiDarkBlue p-4 rounded-2xl"
	>
		<div class="flex flex-col w-full justify-between gap-2">
			<small class="text-textGreen">
				{{ label | translate }}
			</small>
			<small *ngIf="subLabel" class="text-semiDarkGrey">
				{{ subLabel | translate }}
			</small>
			<p class="text-fontColor text-base">
				{{ value }}
				<small>{{ valueLabel | translate }}</small>
			</p>
		</div>
	</div>
</ng-template>
