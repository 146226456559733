import { inject, DestroyRef } from '@angular/core';
import { EventEmitter, OnInit, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Photos } from '@sportyano/core/models/account/news';
import { ShowinterestService } from '@sportyano/core/services/generals/showinterest.service';
import { LanguagesService } from '@sportyano/core/services/language services/languages.service';
@Component({
	selector: 'app-cart-player',
	templateUrl: './cart-player.component.html',
	styleUrl: './cart-player.component.scss'
})
export class CartPlayerComponent implements OnInit {
	@Input() isSelected: boolean = true
	@Input() totalNumberOfItems: number = 100
	@Input() currentItem: number = 1
	@Input() index: number = 0
	@Input() image: string
	@Input() currentImage: string
	@Input() isShowSelected: boolean = true
	@Output() isSelectedChange = new EventEmitter<boolean>();
	@Output() nextClicked = new EventEmitter<number>();
	@Output() prevClicked = new EventEmitter<number>();
	@Output() deleteImage: EventEmitter<Photos> = new EventEmitter<Photos>();
	@Input() data: Photos
	isHeart: boolean = false
	visible: boolean = false
	shareModel: boolean = false
	currentLanguage: string = 'ar'

	private destroyRef = inject(DestroyRef);
	constructor(private languagesService: LanguagesService, private showinterest: ShowinterestService) {

	}
	ngOnInit(): void {
		this.languagesService.language$.subscribe({
			next: (language: string) => {
				this.currentLanguage = language;
			},
		});
	}

	toggelHeart(e: any) {
		e.stopPropagation()
		this.isHeart = !this.isHeart
		// this.showinterest.makePlayersInterest(1).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
		// })
	}

	share(e: any) {
		e.stopPropagation()
		this.shareModel = true
	}

	onChildValueChange() {
		this.isSelected = !this.isSelected;
		this.isSelectedChange.emit(this.isSelected);
	}
	public deleteUserImage(data:Photos ) {
		this.deleteImage.emit(data);
	}
}
