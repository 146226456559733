import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ExtraTabItam } from '@sportyano/core/models/nav-tabs/ExtraTabItam';
import { TabItem } from '@sportyano/core/models/nav-tabs/TabItem';

@Component( {
	selector: 'nav-tabs',
	templateUrl: './nav-tabs.component.html',
	styleUrl: './nav-tabs.component.scss'
} )
export class NavTabsComponent {

	@Input( { required: true } ) tabItems: TabItem[] = [];
	@Input() extraTabItems: ExtraTabItam[] = [];

	constructor ( private router: Router ) { }

	checkShowExtraTab ( activeTab: string ): boolean {
		return this.router.url.includes( activeTab ) ? true : false
	}

}
