import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterMessagesService } from '@sportyano/core/services/toaster-messages/toaster-messages.service';
import { DialogModule } from 'primeng/dialog';
@Component({
	selector: 'app-share-modal',
	templateUrl: './share-modal.component.html',
	styleUrl: './share-modal.component.scss',
	standalone:true,
	imports:[CommonModule,DialogModule],
})
export class ShareModalComponent {
	@Input() visible: boolean = false;
	@Input() urlToShare: string
	@Output() OnHide = new EventEmitter<boolean>();
	constructor(private toastr: ToasterMessagesService, private translate: TranslateService) { }


	hide() {
		this.visible = false;
		this.OnHide.emit(false);
	}

	copyUrl() {
		navigator.clipboard.writeText(location.href);
		this.toastr.showSuccess(this.translate.instant('copied'));
	}

	getCurrentUrl(): string {
		return this.urlToShare || location.href;
	}

	encodeURIComponentFunction(url: string) {
		return encodeURIComponent(url);
	}
}
