import { Component, Input } from '@angular/core';
import { LanguagesService } from '@sportyano/core/services/language services/languages.service';

@Component({
	selector: 'app-cart-review',
	templateUrl: './cart-review.component.html',
	styleUrl: './cart-review.component.scss'
})
export class CartReviewComponent {
	@Input() cardData: any
	@Input() cardType: 'playground' | 'academy';

	constructor() { }

	ngOnInit(): void { }
}
