<section>
	@if (userHasPermission) {
	<div
		class="flex flex-row justify-between items-center flex-wrap gap-4 lg:gap-0"
	>
		<div>
			<h3 class="text-3xl text-fontColor font-bold">
				{{ "account.account_management.tabs_items.Videos" | translate }}
			</h3>
			<small class="text-gray-500">{{
				"account.account_management.news_Media.uploadedVideoNote"
					| translate
			}}</small>
		</div>
		<div class="flex flex-row justify-end items-center">
			<button
				(click)="deleteItems()"
				*ngIf="anyItemSelected()"
				class="ms-auto flex flex-row justify-center items-center w-40 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-lg shadow-md hover:border hover:border-lightBlue hover:text-lightBlue change-icon"
			>
				{{ "account.account_management.news_Media.Delete" | translate }}
			</button>

			<button
				[disabled]="numberOfUploadedVideo() == maximumUploadedVideo"
				[ngClass]="
					numberOfUploadedVideo() == maximumUploadedVideo
						? 'bg-semiDarkGrey text-inputFieldBackground'
						: 'bg-lightBlue text-fontColor hover:border hover:border-lightBlue'
				"
				(click)="addNewPost()"
				class="ms-3 me-2 w-40 h-10 rounded-lg shadow-md"
			>
				<span class="text-base">
					<i class="pi pi-plus"></i>

					{{
						"account.account_management.news_Media.Add_New_Video"
							| translate
					}}
				</span>
				<small
					*ngIf="numberOfUploadedVideo()"
					class="text-inputFieldBackground"
					>{{
						"(" +
							numberOfUploadedVideo() +
							"/" +
							maximumUploadedVideo +
							")"
					}}</small
				>
			</button>
		</div>
	</div>
	}

	<div
		class="mt-8 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 justify-between items-center"
	>
		@for (item of listOfVideos(); track $index) {
		<app-cart-video
			[data]="item"
			[isShowSelected]="userHasPermission"
			[(isSelected)]="item.isSelected"
			(deleteVideo)="deleteUserVideo($event)"
			(editVideo)="onEditVideo($event)"
			[canDelete]="true"
		/>}
	</div>

	<!-- <paggination [rows]="10" [totalRecords]="100" (pageChanged)="changePage($event)" /> -->
</section>

<add-post-pop-up
	[visible]="willAddItem"
	(onCancel)="willAddItem = false"
	(onConfirm)="onVideoUpload()"
	[isVideo]="true"
	[fileType]="fileType"
></add-post-pop-up>
<popUp-success-pop-up
	[message]="message"
	[displayToast]="showToaster"
></popUp-success-pop-up>

<p-confirmDialog #cd>
	<ng-template pTemplate="headless" let-message>
		<div
			class="flex flex-col items-center p-5 bg-white rounded-lg shadow-lg"
		>
			<div
				class="rounded-full bg-darkYellow bg-primary flex justify-center items-center h-24 w-24"
			>
				<i class="pi pi-question text-5xl"></i>
			</div>
			<span class="font-bold text-2xl block mb-2 mt-4">
				{{ message.header }}
			</span>
			<p class="mb-0">{{ message.message }}</p>
			<div class="flex items-center gap-2 mt-4">
				<button
					pButton
					[label]="'confirmation.submit' | translate"
					(click)="cd.accept()"
					class="w-32 bg-green-500 text-white py-2 px-4 rounded-lg"
				></button>
				<button
					pButton
					[label]="'confirmation.cancel' | translate"
					(click)="cd.reject()"
					class="w-32 border bg-gray-200 text-semiDarkGrey text-primary py-2 px-4 rounded-lg"
				></button>
			</div>
		</div>
	</ng-template>
</p-confirmDialog>

<div class="card flex justify-content-center">
	<!-- <p-button icon="pi pi-times" (onClick)="visibleEditVideo = true"  /> -->
	<add-post-pop-up
		[visible]="visibleEditVideo"
		[isEdit]="true"
		(onCancel)="visibleEditVideo = false"
		(onConfirm)="fn($event)"
		[isVideo]="true"
		[videos]="_selectedVideo"
	></add-post-pop-up>
</div>
