<div class="academy cursor-pointer p-2 bg-inputFieldBackground rounded-lg overflow-hidden relative flex gap-3">
	<div class="photo overflow-hidden rounded-lg w-full h-72">
		@if (cardData.pictures.length > 0 && cardData.pictures[0].url) {
		<img class="w-full h-full object-cover" [src]="cardData.pictures[0].url" alt="">
		} @else {
		<img class="w-full h-full object-cover" src="assets/images/image/Component 146 – 1.png" alt="">
		}
	</div>
	<div class="grid grid-cols-12 w-full">
		<div class="col-span-8 flex gap-4">
			<h3 class="text-white capitalize text-lg">{{ cardData.academy_name}}</h3>
			<p class="text-white capitalize text-lg">{{ cardData.branch_name }}</p>
		</div>
		<!-- <div class="col-span-4 flex justify-end px-4 items-baseline gap-2">
			<img class="size-4" src="assets/icons/Icon ionic-ios-star.svg" alt="">
			<div class="num-rate text-white">240</div>
		</div> -->

		<div class="col-span-12 md:col-span-6">
			<p class="desc mt-3 text-fontColor text-sm word-break-word"> {{ cardData.description }}</p>
		</div>

		<div class="col-span-12 md:col-span-6 md:col-start-8">
			<p class="desc mt-3 text-fontColor text-sm"> {{ cardData.address }}</p>
		</div>

		<div class="col-span-12 flex items-center">
			<div class="flex gap-4">
				@for (sport of cardData.sports.slice(0,6); track $index) {

				<p class="title mt-2 text-sm text-white">{{ sport.name }}</p>
				}
			</div>
		</div>
	</div>
</div>
