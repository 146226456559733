import { Observable } from "rxjs";
import { Injectable, inject } from "@angular/core";
import { CommonService } from "@sportyano/core/services/shard/common.service";
import { environment } from "src/environments/environment";
import { AcademyCardResponse } from "@sportyano/core/models/academy-card/academy-card.model";
import { SingleAcademyResponse } from "@sportyano/core/models/single-academy-details/single-academy.model";
import { HttpClient } from "@angular/common/http";
import { VerifyPlayerStatus } from "@sportyano/account/components/branches-management/player-list/models/const/verify-player-status";
import { IPaginatedResponse } from "@sportyano/core/models/response/response.interface";
import { IPlayerProfile } from "@sportyano/core/models/account/profile";
import { PlayerProfile } from "@sportyano/core/models/player-card.model";
import { IVerifyPlayerPayload } from "@sportyano/account/components/branches-management/player-list/models/interface/verify-player-payload.interface";
import { PayloadUtils } from "@sportyano/shared/utilis/payload-utilits";

@Injectable({
	providedIn: "root",
})
export class AcademyService {
	// Inject
	private _httpClient = inject(HttpClient);
	constructor(private commonServices: CommonService) {}

	getAcademies(params: any): Observable<AcademyCardResponse> {
		return this.commonServices.CommonGetWithParamRequests(
			`${environment.baseUrl}home-page/academies`,
			params
		);
	}

	getSingleAcademy(id: string): Observable<SingleAcademyResponse> {
		return this.commonServices.CommonGetRequests(
			`${environment.baseUrl}home-page/academy/${id}`
		);
	}

	public getPlayerVerification(
		payload: Partial<IVerifyPlayerPayload>
	): Observable<IPaginatedResponse<PlayerProfile>> {
		const _payload = PayloadUtils.filterFalsyValues(payload);
		return this._httpClient.post<IPaginatedResponse<PlayerProfile>>(
			`${environment.baseUrl}academies/list-academy-players`,
			{
				..._payload,
			}
		);
	}

	public updatePlayerVerification(payload: {
		playerId: number;
		state: VerifyPlayerStatus;
	}): Observable<{ message: string; success: boolean }> {
		return this._httpClient.put<{ message: string; success: boolean }>(
			`${environment.baseUrl}academies/update-request-player/${payload.playerId}`,
			{
				status: payload.state,
			}
		);
	}
}
