<div class="p-2 rounded-md flex flex-col justify-between h-full">

	<img src="assets/images/image/Mask Group 38.png" class="w-4/5" alt="">



	<div class="flex justify-between items-end">

		<div class="flex flex-col justify-around items-start">

			<div class="flex justify-start items-start mt-4">
				<div class="w-[20px] h-[20px]">
					<svg xmlns="http://www.w3.org/2000/svg" fill="#0569b1" viewBox="0 0 384 512">
						<path
							d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
					</svg>
				</div>

				<p class="text-sm text-fontColor ms-1 ">{{locationData.address}}</p>

			</div>


			<div class="flex justify-start items-start mt-4">
				<p class="text-sm text-fontColor ms-1 ">{{locationData.mobile_number}}</p>

			</div>

		</div>



		<a href="{{locationData.location_url}}" target="_blank"
			[ngClass]="{'pointer-events-none': !locationData.location_url}"
			class="ms-auto flex flex-row justify-center items-center w-32 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-md shadow-md  hover:border-lightBlue  hover:bg-lightBlue hover:text-fontColor">
			{{ "SPORT_ACADEMIES.Get_Direction" | translate }}
		</a>


	</div>

</div>
