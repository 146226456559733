<div class="w-full relative mb-8">

	<div class="mb-4">
		<button (click)="reset()" class="w-fit bg-inputFieldBackground p-2 rounded transition-colors">
			<svg height="25" viewBox="0 0 512 512" width="25" xmlns="http://www.w3.org/2000/svg">
				<path fill="white"
					d="m320 170.666667c28.289759 0 55.420843 11.238063 75.424723 31.241943 20.003881 20.00388 31.241944 47.134964 31.241944 75.424723 0 58.910374-47.756294 106.666667-106.666667 106.666667s-106.666667-47.756293-106.666667-106.666667c0-58.910373 47.756294-106.666666 106.666667-106.666666zm0 21.333333c-47.128299 0-85.333333 38.205035-85.333333 85.333333 0 47.128299 38.205034 85.333334 85.333333 85.333334s85.333333-38.205035 85.333333-85.333334c0-47.128298-38.205034-85.333333-85.333333-85.333333zm36.543147 33.705237 15.084949 15.08495-36.544192 36.54305 36.544192 36.543243-15.084949 15.084949-36.543243-36.544192-36.543051 36.544192-15.084949-15.084949 36.543-36.543243-36.543-36.54305 15.084949-15.08495 36.543051 36.543zm-15.209814-225.705237-149.333333 181.999v122.001h-42.666667l-.000333-122-149.333-182zm-90.218666 42.6666667h-160.9173337l80.4693337 98.0480003z"
					fill-rule="evenodd" transform="translate(42.666667 85.333333)" />
			</svg>
		</button>
	</div>

	<div class="relative">
		<input type="search" [(ngModel)]="filter.search" (input)="sendFilter()"
			class="border mb-4 border-fontColor text-base rounded-md text-fontColor py-2 w-full bg-transparent focus:outline-none focus:shadow-none focus:border-fontColor ltr:pr-14 rtl:pl-14"
			placeholder="{{'account.branches_management.player_list.search' | translate}}" />
		<svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-6 h-6 absolute top-2 end-5 stroke-fontColor">
			<path stroke-linecap="round" stroke-linejoin="round"
				d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"></path>
		</svg>
	</div>

	<!-- Sorting -->
	<h3 class="capitalize text-textYellow font-semiBold mt-3 mb-2 text-sm">
		{{ "advanced_filteration.sorting" | translate }}
	</h3>
	<p-dropdown [options]="sorting" placeholder="{{ 'advanced_filteration.sport' | translate }}" optionLabel="name"
		(onChange)="sendFilter()" optionValue="name">
		<ng-template let-item pTemplate="item">
			<div class="flex items-center justify-between" (click)="filter.sort=item">
				<span>{{ item.name }}</span>
				<img width="20px" height="20px" src="assets/icons/sort.svg" alt="">
			</div>
		</ng-template>
	</p-dropdown>

	<!-- sports -->
	<h3 class="capitalize text-textYellow font-semiBold mt-5 mb-2 text-sm">
		{{ "top_sports_men.filter_options.all_sports" | translate }}
	</h3>
	<p-dropdown [options]="sports" (onChange)="getSportFilter($event)" [(ngModel)]="filter.selectSports"
		(onChange)="sendFilter()" placeholder="{{ 'top_sports_men.filter_options.all_sports' | translate }}"
		optionLabel="name" optionValue="id">
	</p-dropdown>

	<!-- Positions filterType-->
	<h3 class="capitalize text-textYellow font-semiBold mt-5 mb-2 text-sm">
		{{ "advanced_filteration.position" | translate }}
	</h3>
	<p-dropdown [options]="positions" [(ngModel)]="filter.selectPosition" (onChange)="sendFilter()"
		placeholder="{{ 'advanced_filteration.position' | translate }}" optionLabel="name" optionValue="id">
	</p-dropdown>
	<ng-container *ngIf="filterType==permission.player && filterSport.length>0">
		<h3 class="capitalize text-textYellow font-semiBold mt-5 mb-2 text-sm">
			{{ "account.account_management.player_Info.Position" | translate }}
		</h3>
		<p-dropdown [options]="filterSport" [(ngModel)]="filter.filterSport"
			placeholder="{{ 'account.account_management.player_Info.Position' | translate }}" optionLabel="name"
			optionValue="id">
			(onChange)="sendFilter()"
		</p-dropdown>
	</ng-container>

	<!-- countries -->
	<h3 class="capitalize text-textYellow font-semiBold mt-5 mb-2 text-sm">
		{{ "Country" | translate }}
	</h3>
	<p-dropdown [options]="countries" [filter]="true" (onChange)="getCity($event)" [(ngModel)]="filter.SearchByCountry"
		placeholder="{{ 'Country' | translate }}" optionLabel="name" optionValue="id" (onChange)="sendFilter()">
	</p-dropdown>

	<!-- City -->
	<h3 class="capitalize text-textYellow font-semiBold mt-5 mb-2 text-sm">
		{{ "City" | translate }}
	</h3>
	<p-dropdown [options]="cities" [filter]="true" [(ngModel)]="filter.SearchByCity" (onChange)="sendFilter()"
		placeholder="{{ 'City' | translate }}" optionLabel="name" optionValue="id">
	</p-dropdown>

	<ng-container *ngIf="filterType==permission.player || filterType==permission.academy">
		<!-- academy -->
		<h3 class="capitalize text-textYellow font-semiBold mt-5 mb-2 text-sm">
			{{ "Academy_name" | translate }}
		</h3>
		<p-dropdown [options]="academies" [filter]="true" [(ngModel)]="filter.selectAcademy" (onChange)="sendFilter()"
			placeholder="{{ 'Academy_name' | translate }}" optionLabel="academy_name" optionValue="id">
		</p-dropdown>
	</ng-container>

	<ng-container *ngIf="filterType==permission.player">
		<!-- Nationality -->
		<h3 class="capitalize text-textYellow font-semiBold mt-5 mb-2 text-sm">
			{{ "advanced_filteration.nationality" | translate }}
		</h3>
		<p-multiSelect [(ngModel)]="filter.selectConutry" [options]="countries" (onChange)="sendFilter()"
			placeholder="{{ 'advanced_filteration.nationality' | translate }}" optionLabel="name" optionValue="id">
		</p-multiSelect>

		<!-- Player age -->
		<h3 class="capitalize text-textYellow font-semiBold mt-5 mb-2 text-sm">
			{{ "advanced_filteration.age" | translate }}
		</h3>
		<mat-slider [min]="minimumAge" [max]="maximumAge">
			<input [(ngModel)]="chosenMinimumAge" matSliderStartThumb [matTooltip]="'' + chosenMinimumAge" />
			<input [(ngModel)]="chosenMaximumAge" matSliderEndThumb [matTooltip]="'' + chosenMaximumAge" />
		</mat-slider>

		<!-- Checkbox filters -->
		<div class="policy flex items-center">
			<div class="__policy-checkbox inline-block">
				<p-checkbox [(ngModel)]="filter.contract" [binary]="true" [ngModelOptions]="{ standalone: true }"
					inputId="policyCheckbox"></p-checkbox>
			</div>
			<label class="text-fontColor select-none cursor-pointer inline-block ps-4 my-4" for="policyCheckbox">
				{{ "advanced_filteration.contract" | translate }}
			</label>
		</div>


	</ng-container>
</div>
