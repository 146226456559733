import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-location-card',
	templateUrl: './location-card.component.html',
	styleUrl: './location-card.component.scss'
})
export class LocationCardComponent {
	@Input() locationData: LocationData


}

interface LocationData {
	lat: number,
	long: number,
	mobile_number: string,
	address: string,
	location_url: string
}
