import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CityResponse } from "@sportyano/core/models/cities/cities.model";
import { CountriesResponse } from "@sportyano/core/models/countries/countries.model";
import { Country } from "@sportyano/core/models/generals/country.model";
import { PlayerProfileFieldResponse } from "@sportyano/core/models/sports-human/sports-human.model";
import { SportResponse } from "@sportyano/core/models/sports/sports.model";
import {
	BehaviorSubject,
	catchError,
	map,
	Observable,
	of,
	shareReplay,
	tap,
} from "rxjs";
import { environment } from "src/environments/environment";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";

export type CropperDialogResult = {
	blob: Blob;
	imageUrl: string;
};

@Injectable({
	providedIn: "root",
})
export class GeneralService {
	private croppedImageState = new BehaviorSubject<CropperDialogResult | null>(
		null!
	);

	private countries: CountriesResponse;
	private sports: SportResponse;
	private cities: CityResponse;
	private sportOptions: PlayerProfileFieldResponse;
	private countryId: number = 0;
	private sportId: number = 0;
	private sports$: Observable<SportResponse>;
	userType: permission;

	constructor(private http: HttpClient) {}

	getSportsHumen(sports_Id: number): Observable<PlayerProfileFieldResponse> {
		if (this.sportId == sports_Id) {
			return of(this.sportOptions);
		} else {
			return this.http
				.get<PlayerProfileFieldResponse>(
					// update here 30-5 ahmed omar
					`${environment.baseUrl}sports-property/`
				)
				.pipe(
					tap((sportOptions) => {
						this.sportOptions = sportOptions;
						this.sportId = sports_Id;
					})
				);
		}
	}

	fetchVideos(filter: any): Observable<any> {
		let params = new HttpParams()
			.set("sort_direction", filter.sort_direction)
			.set("contract", filter.contract.toString())
			.set("country_id", filter.country_id)
			.set("sport_id", filter.sport_id);

		if (filter.sport_option_position_id) {
			params = params.set(
				"sport_option_position_id",
				filter.sport_option_position_id
			);
			// Remove age_from and age_to if sport_option_position_id is present
		} else {
			params = params
				.set("age_from", filter.age_from)
				.set("age_to", filter.age_to);
		}

		return this.http.get(`${environment.baseUrl}players/videos`, {
			params,
		});
	}

	getSportsPositions(sports_Id: number): Observable<any> {
		if (this.sportId === sports_Id) {
			return of(this.sportOptions);
		} else {
			return this.http
				.get<any>(`${environment.baseUrl}sports/${sports_Id}/positions`)
				.pipe(
					map((response) => response.records),
					tap((sportOptions) => {
						this.sportOptions = sportOptions;
						this.sportId = sports_Id;
					})
				);
		}
	}

	getSports(): Observable<SportResponse> {
		if (!this.sports$) {
			this.sports$ = this.http
				.get<SportResponse>(`${environment.baseUrl}sports`)
				.pipe(
					tap((sports) => {
						this.sports = sports;
					}),
					shareReplay(1), // Share the observable and replay the last emitted value to new subscribers
					catchError((error) => {
						console.error("Failed to fetch sports", error);
						// Handle the error accordingly, for example, return an empty result
						return of();
					})
				);
		}

		return this.sports$;
	}
	//delete programme
	//

	deletePrograme(id: number, userType: string | null): Observable<void> {
		if (userType === "playground") {
			return this.http.delete<void>(
				`${environment.baseUrl}playgrounds/grounds/${id}`
			);
		} else if (userType === "academy") {
			return this.http.delete<void>(
				`${environment.baseUrl}academies/programs/${id}`
			);
		} else {
			throw new Error("Invalid user type");
		}
	}

	getCountries(): Observable<CountriesResponse> {
		if (this.countries) {
			return of(this.countries);
		} else {
			return this.http
				.get<CountriesResponse>(`${environment.baseUrl}countries`)
				.pipe(tap((countries) => (this.countries = countries)));
		}
	}

	// Ahmed Update on 28-5
	getAcademyList(): Observable<any> {
		return this.http.get(`${environment.baseUrl}academies/all-academies`);
	}

	getCities(countryId: number): Observable<CityResponse> {
		if (this.countryId == countryId) {
			return of(this.cities);
		} else {
			let params = new HttpParams().set("country_id", countryId);
			return this.http
				.get<CityResponse>(`${environment.baseUrl}cities`, {
					params: params,
				})
				.pipe(
					tap((cities) => {
						this.cities = cities;
						this.countryId = countryId;
					})
				);
		}
	}

	setCroppedImageState(value: CropperDialogResult | null) {
		this.croppedImageState.next(value);
	}

	getCroppedImageState() {
		return this.croppedImageState.asObservable();
	}

	getCountryById(id: number): Country | undefined {
		return this.countries?.records.find((country) => country.id === id);
	}
}
