import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-head-section",
	templateUrl: "./head-section.component.html",
	styleUrl: "./head-section.component.scss",
})
export class HeadSectionComponent {
	constructor(private _translate: TranslateService) { }
	@Input() sectionHeadTitle: string =
		this._translate.instant("head_section_title");
	@Input() sectionSubHeadTitle: string | undefined = "";
	@Input() sectionCoverPicture: string | null = '../../../../assets/images/image/academy-cover.jpg';

}
