<div class="tab-group">
	<div class="flex justify-between" *ngIf="tabItems && tabItems.length > 0">
		<ul class="tab-header w-fit">
			<!-- Use ng-container to handle multiple structural directives -->
			<ng-container *ngFor="let menuItem of tabItems; let i = index">
				<li class="tab-item" [ngClass]="{'disabled': menuItem.disabled}">
					<a class="tab-link whitespace-nowrap" [routerLink]="!menuItem.disabled ? [menuItem.Route] : null"
						routerLinkActive="active" [class.disabled]="menuItem.disabled">
						{{ menuItem.Name | translate }}
						<small
						class="text-gray-500" *ngIf="menuItem.disabled"
						>{{
							"errorMessages.activeSoon"
								| translate
						}}
					</small>
					</a>
				</li>
			</ng-container>
		</ul>

		<ul class="tab-header w-fit">
			<!-- Separate structural directives with ng-container -->
			<ng-container *ngFor="let item of extraTabItems; let i = index">
				<ng-container *ngIf="checkShowExtraTab(item.showOnRoute)">
					<li class="tab-item" [ngClass]="{'disabled': item.disabled}">
						<a class="tab-link whitespace-nowrap" [routerLink]="!item.disabled ? [item.Route] : null"
							routerLinkActive="active" [class.disabled]="item.disabled">
							{{ item.Name | translate }}
						</a>
					</li>
				</ng-container>
			</ng-container>
		</ul>
	</div>

	<div class="tab-body-wrapper">
		<ng-content></ng-content>
	</div>
</div>
