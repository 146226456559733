import { Pipe, PipeTransform } from "@angular/core";
import {
	ITimeSlotRequest,
	TimeSlot,
} from "@sportyano/core/models/programs/time-slots.model";
import { DateTimeUtils } from "@sportyano/shared/utilis/date-time-utilits";

@Pipe({
	name: "checkSlotAvailability",
	standalone: true,
})
export class CheckSlotAvailability implements PipeTransform {
	transform(
		slotData: { date: string; time: string },
		slots: Map<string, TimeSlot[]>,
		localBookedSlots: Map<string, ITimeSlotRequest>
	): TimeSlot | "false" {
		const _slotTime = DateTimeUtils.convertTo24Hour(slotData.time);
		const concatValue = slotData.date.concat(" ", _slotTime);
		if (localBookedSlots.has(concatValue)) {
			return localBookedSlots.get(concatValue) as any as TimeSlot
		}

		if (slots.has(slotData.date)) {
			// Check in  Response
			const slot = slots.get(slotData.date)?.find((slot) => {
				const [slotDate, slotTime] = slot.slot.split(" ");
				return slotDate === slotData.date && slotTime === _slotTime;
			});			
			return slot || "false";
		}

		return "false";
	}
}
