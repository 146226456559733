<section
	class="h-[65vh] w-screen section-header"
	[ngStyle]="{
		background:
			'url(&quot;' +
			sectionCoverPicture +
			'&quot;) no-repeat center center/cover'
	}"
>
	<div class="w-4/5 mx-auto h-full grid items-center section-content">
		<h1
			class="text-fontColor uppercase font-black lg:text-6xl md:text-4xl text-2xl"
		>
			{{ sectionHeadTitle }}

			@if (sectionSubHeadTitle && sectionSubHeadTitle.length > 0) {
			<p
				class="text-fontColor lg:text-2xl text-xl mt-4 head1 d-inline-block"
			>
				{{ sectionSubHeadTitle }}
			</p>
			}
		</h1>
	</div>
</section>
