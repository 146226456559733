import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	inject,
} from "@angular/core";
import {
	FormBuilder,
	FormGroup,
	ValidationErrors,
	Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PlayerService } from "@sportyano/core/services/playerProfile/player.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import intlTelInput from "intl-tel-input";

@Component({
	selector: "app-change-mobile",
	templateUrl: "./change-mobile.component.html",
	styleUrl: "./change-mobile.component.scss",
})
export class ChangeMobileComponent implements OnInit {
	@ViewChild("phoneInput") set phoneInputRef(ref: ElementRef) {
		if (ref) {
			this.phoneInput = ref;
			this.initializePhoneNumberInput();
		}
	}
	private phoneInput!: ElementRef;

	private iti: any;
	protected changeMobileForm: FormGroup;
	private _fb = inject(FormBuilder);
	private _translateService = inject(TranslateService);
	@Input() display: boolean;
	@Input() phoneNumber: string | undefined;
	@Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCancle: EventEmitter<void> = new EventEmitter<void>();
	private _toaster = inject(ToasterMessagesService);
	constructor(private services: PlayerService) {}
	ngOnInit(): void {
		this.buildChangeMobileForm();
	}

	private initializePhoneNumberInput() {
		const inputElement = this.phoneInput.nativeElement;
		inputElement.addEventListener("keyup", () => {
			this.changeMobileForm.controls["phone_number"].setValue(
				inputElement.value
			);
		});

		inputElement.addEventListener("countrychange", () => {
			this.changeMobileForm.controls["phone_number"].setValue("");
		});

		this.iti = intlTelInput(inputElement, {
			allowDropdown: true,
			autoInsertDialCode: true,
			autoPlaceholder: "aggressive",
			formatOnDisplay: true,
			initialCountry: "eg",
			nationalMode: true,
			separateDialCode: true,
			placeholderNumberType: "MOBILE",
			utilsScript: "node_modules/intl-tel-input/build/js/utils.js",
		});
	}

	private buildChangeMobileForm() {
		this.changeMobileForm = this._fb.group({
			password: ["", [Validators.required, Validators.minLength(6)]],
			phone_number: ["", [Validators.required, this.phoneValidator()]],
		});
	}

	phoneValidator(): ValidationErrors | null {
		return () => {
			if (!this.phoneInput) {
				return null;
			}
			const isValid = this.iti.isValidNumberPrecise();
			return isValid ? null : { invalidPhone: true };
		};
	}

	update() {
		if (this.changeMobileForm.invalid) {
			return;
		}

		let dataToSend = {
			password: this.changeMobileForm.value.password,
			phone_number: this.changeMobileForm.value.phone_number
				.split(" ")
				.join(""),
		};

		this.services.updatePlayerPhone(dataToSend).subscribe({
			next: (res) => {
				this.onCancle.emit();
				this.onEdit.emit();
				this._toaster.showSuccess(res.message);
			},
		});
	}

	getErrorTooltip(controlName: string): string {
		const control = this.changeMobileForm.get(controlName);
		if (control?.invalid && (control?.dirty || control?.touched)) {
			if (control?.hasError("pattern")) {
				return this._translateService.instant(
					"authentication.errors.phoneNumber"
				);
			} else {
				return this._translateService.instant(
					"authentication.errors.phoneNumber"
				);
			}
		}
		return "";
	}

	showTooltip(controlName: string): boolean | null {
		const control = this.changeMobileForm.get(controlName);
		return control && control.invalid && control.dirty && control.touched;
	}

	onCancelEmitter() {
		this.changeMobileForm.reset();
		this.onCancle.emit();
	}
}
