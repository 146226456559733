import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { permission } from "@sportyano/core/models/permissions/permission";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { ACCEPTED_IMAGE_EXTENSIONS_LIST } from "@sportyano/shared/models/const/constant";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export interface iMediaVideo {
	success: boolean;
	records: Object[];
}
@Injectable({
	providedIn: "root",
})
export class MediaService {
	private _authService = inject(AuthService);
	apiUrl = environment.baseUrl + "players/videos";

	constructor(private _HttpClient: HttpClient) {}

	getMediaVideos(params?: any): Observable<any> {
		return this._HttpClient.get(this.apiUrl, { params });
	}
	getTopMediaVideos(): Observable<any> {
		let params = new HttpParams().set("paginate", 3);
		return this._HttpClient.get(this.apiUrl, { params });
	}

	public deleteVideo(id: number, videoId: number): Observable<Object> {
		let userType = this._authService.getUserType();
		let URL: string = "";
		switch (userType) {
			case permission.player:
				URL = environment.baseUrl + `players/${id}/videos/${videoId}`;
				break;
			case permission.academy:
				URL = environment.baseUrl + `academies/${id}/videos/${videoId}`;
				break;
			case permission.playground:
				URL =
					environment.baseUrl + `playgrounds/${id}/videos/${videoId}`;
				break;
			default:
				break;
		}
		return this._HttpClient.delete(URL);
	}
	public deleteImage(id: number, imageId: number): Observable<Object> {
		let userType = this._authService.getUserType();
		let URL: string = "";
		switch (userType) {
			case permission.player:
				URL = environment.baseUrl + `players/${id}/images/${imageId}`;
				break;
			case permission.academy:
				URL = environment.baseUrl + `academies/${id}/images/${imageId}`;
				break;
			case permission.playground:
				URL =
					environment.baseUrl + `playgrounds/${id}/images/${imageId}`;
				break;
			default:
				break;
		}
		return this._HttpClient.delete(URL);
	}
	public updateVideoTitleAndDescription(id: number, videoId: number, body: any): Observable<Object> {
		let userType = this._authService.getUserType();
		let URL: string = "";
		switch (userType) {
			case permission.player:
				URL = environment.baseUrl + `players/${id}/videos/${videoId}`;
				break;
			case permission.academy:
				URL = environment.baseUrl + `academies/${id}/videos/${videoId}`;
				break;
			case permission.playground:
				URL =
					environment.baseUrl + `playgrounds/${id}/videos/${videoId}`;
				break;
			default:
				break;
		}
		return this._HttpClient.put(URL,body );
	}

	public isAcceptedImageType(fileType: string): boolean {
		return ACCEPTED_IMAGE_EXTENSIONS_LIST.some((type) =>
			fileType.includes(type)
		);
	}

	public getVideosById(id:number){
		let userType = this._authService.getUserType();
		let URL: string = "";
		switch (userType) {
			case  permission.player:
				URL = environment.baseUrl + `players/${id}/videos`;
				break;
			case permission.academy:
				URL = environment.baseUrl + `academies/${id}/videos`;
				break;
			case permission.playground:
				URL = environment.baseUrl + `playgrounds/${id}/videos`;
				break;
			default:
				break;
		}

		return this._HttpClient.get(URL);
	}
}
