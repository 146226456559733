import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-cart-academy',
	templateUrl: './cart-academy.component.html',
	styleUrl: './cart-academy.component.scss'
})
export class CartAcademyComponent {
	@Input() data: any
}
