import { Observable } from "rxjs";
import { inject, Injectable } from "@angular/core";
import { CommonService } from "@sportyano/core/services/shard/common.service";
import { environment } from "src/environments/environment";
import { PlaygroundResponse } from "@sportyano/core/models/playground-card/playground-card.model";
import { SinglePlaygroundResponse } from "@sportyano/core/models/single-playground-details/single-playground.model";
import { ITimeSlotRequest, TimeSlotResponse } from "@sportyano/core/models/programs/time-slots.model";
import { HttpClient } from "@angular/common/http";
import { IPlaygroundReservedResponse } from "@sportyano/shared/components/custom-calendar/models/const/reserved-response";

@Injectable({
	providedIn: "root",
})
export class PlaygroundService {
	private _http = inject(HttpClient);
	constructor(private commonServices: CommonService) {}

	getPlaygrounds(params: any): Observable<PlaygroundResponse> {
		return this.commonServices.CommonGetWithParamRequests(
			`${environment.baseUrl}home-page/playgrounds`,
			params
		);
	}

	getSinglePlayground(id: string): Observable<SinglePlaygroundResponse> {
		return this.commonServices.CommonGetRequests(
			`${environment.baseUrl}home-page/playgrounds/${id}`
		);
	}

	public updatePlaygroundSchedule(
		playgroundId: string,
		slots: Array<ITimeSlotRequest>
	) {
		const url = `${environment.baseUrl}playgrounds/${playgroundId}/time-slots`;
		return this._http.post(url, { slots: slots });
	}

	public getReservedPlayground(
		playgroundId: string
	): Observable<TimeSlotResponse> {
		const url = `${environment.baseUrl}playgrounds/${playgroundId}/time-slots`;
		return this._http.get<TimeSlotResponse>(url);
	}

	public getReservedPlaygroundDates(
		programId: string,
		startDate: string,
		endDate: string
	): Observable<Array<IPlaygroundReservedResponse>> {
		const url = `${environment.baseUrl}get/booking/list/playground/${programId}?startDate=${startDate}&endDate=${endDate}`;
		return this._http.get<Array<IPlaygroundReservedResponse>>(url);
	}
}
