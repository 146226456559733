import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AcademyCard, Sport } from '@sportyano/core/models/academy-card/academy-card.model';
import { City } from '@sportyano/core/models/cities/cities.model';
import { Countries } from '@sportyano/core/models/countries/countries.model';
import { permission } from '@sportyano/core/models/permissions/permission';
import { Property } from '@sportyano/core/models/sports-human/sports-human.model';
import { AcademyService } from '@sportyano/core/services/academy/academies/academy.service';
import { GeneralService } from '@sportyano/core/services/generals/general.service';

interface Filter {
    sort: SortOptions,
    search: string,
    SearchByCity: string,
    SearchByCountry: string,
    selectConutry: number[],
    selectSports: string,
    selectAcademy: string,
    contract: boolean,
    filterSport?: string,
    selectPosition?: string,
    position: string;
}

interface SortOptions {
    name: string, id: number, isAsc: boolean, corrctName?: string
}

@Component({
    selector: "app-advanced-side-filteration",
    templateUrl: "./advanced-side-filteration.component.html",
    styleUrls: ["./advanced-side-filteration.component.scss"],
})
export class AdvancedSideFilterationComponent implements OnInit {
    @Input() filterType: string;
    @Output() filterOption = new EventEmitter<any>();
    @Output() resetFilter = new EventEmitter<void>();
    readonly minimumAge: number = 1980;
    readonly maximumAge: number = 2020;
    chosenMinimumAge: number = 1990;
    chosenMaximumAge: number = 2010;
    permission = permission;
    countries: Countries[] = [];
    cities: City[] = [];
    sports: Sport[] = [];
    academies: AcademyCard[] = [];
    filterSport: Property[] = [];
    positions: any[] = [];
    egyptId: number | undefined;
    sorting: SortOptions[] = [
        { id: 1, name: this.translate.instant('date'), isAsc: true, corrctName: 'id' },
        { id: 2, name: this.translate.instant('name'), isAsc: true, corrctName: 'name' }
    ];

    filter: Filter = {
        sort: { name: '', id: 0, isAsc: true, corrctName: '' },
        search: '',
        SearchByCity: '',
        SearchByCountry: '',
        selectConutry: [],
        selectSports: '',
        selectAcademy: '',
        contract: false,
        filterSport: '',
        selectPosition: '',
        position: ''
    };

    constructor(private generalService: GeneralService, private academyService: AcademyService, private translate: TranslateService) { }

    ngOnInit(): void {
        this.fetchCountries();
        this.fetchSports();
    }

    private fetchCountries() {
        this.generalService.getCountries().subscribe((data) => {
            if (data) {
                this.countries = data.records;
                this.egyptId = this.countries.find(ele => ele.code === '20')?.id;

                if (this.egyptId) {
                    this.getCity('');
                    this.filter.SearchByCountry = this.egyptId.toString();
                }
            }
            this.fetchDataAcademy();
        });
    }

    private fetchSports() {
        this.generalService.getSports().subscribe((data) => this.sports = data.records);
    }

    getSportFilter(event: any): void {
        if (this.filterType === permission.player) {
            this.generalService.getSportsHumen(event.value).subscribe((data) => {
                this.filterSport = data.records.find((ele) => ele.label === 'position')?.properties || [];
            });
        }
        this.fetchPositions(event.value);
    }

    private fetchPositions(sportId: number): void {
        this.generalService.getSportsPositions(sportId).subscribe((data) => {
            this.positions = data;
        });
    }

    getCity(event: any): void {
        this.generalService.getCities(event?.value || this.egyptId).subscribe((data) => {
            this.cities = data.records;
        });
    }

    private fetchDataAcademy(): void {
        this.academyService.getAcademies(new HttpParams()).subscribe((data) => {
            this.academies = data.records;
        });
    }

	sendFilter(): void {
		const filterObj: any = {
			search: this.filter.search || '',
			sort_by: this.filter.sort.corrctName,
			sort_direction: this.filter.sort.isAsc ? 'asc' : 'desc',
			contract: this.filter.contract,
			nationalities: this.filter.selectConutry || [],
			country_id: this.filter.SearchByCountry || 0,
			city_id: this.filter.SearchByCity || 0,
			sport_id: this.filter.selectSports || 0,
			academy_id: this.filter.selectAcademy || 0,
			property_id: this.filter.filterSport || 0,
		};

		// Add the position filter parameter conditionally
		if (this.filter.selectPosition) {
			filterObj.sport_option_position_id = this.filter.selectPosition;
		} else {
			filterObj.age_from = this.chosenMinimumAge;
			filterObj.age_to = this.chosenMaximumAge;
		}

		this.filterOption.emit(filterObj);
	}



    reset(): void {
        this.filter = {
            sort: { name: '', id: 0, isAsc: true, corrctName: '' },
            search: '',
            SearchByCity: '',
            SearchByCountry: '',
            selectConutry: [],
            selectSports: '',
            selectAcademy: '',
            contract: false,
            filterSport: '',
            selectPosition: '',
            position: ''
        };
        this.chosenMinimumAge = 1990;
        this.chosenMaximumAge = 2010;

        this.resetFilter.emit();
    }
}
