import { Component, OnInit, inject } from '@angular/core';
import { LoginComponent } from '@sportyano/auth/login/login.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
	selector: 'app-login-popup',
	templateUrl: './login-popup.component.html',
	styleUrl: './login-popup.component.scss'
})
export class LoginPopupComponent extends LoginComponent {
	isMobileScreen: boolean;
	breakpointObserver = inject(BreakpointObserver);

	checkScreenSize(): void {
		this.breakpointObserver.observe([
			Breakpoints.Handset, // Mobile devices
			Breakpoints.Tablet,  // Tablet devices
			Breakpoints.Web      // Desktop devices
		]).subscribe(result => {
			if (result.matches) {
				// Mobile or Tablet view
				this.isMobileScreen = true;
			} else {
				// Desktop view
				this.isMobileScreen = false;
			}
		});
	}


	closePopup() {
		this._authService.setLoginPopupState(false);
		this.loginForm.reset();
	}
}
